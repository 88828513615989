import { GetServerSidePropsContext } from 'next'

import { Notifier } from '@miimosa/protocol'

import { BaseAPI, Result } from '../api'

import { ErrorV3, createV3Client, toV3Result } from './base'

export class NotificationsAPI extends BaseAPI {
  constructor(ctx?: GetServerSidePropsContext) {
    super(createV3Client(ctx), toV3Result)
  }

  async getUnreadNotifications(
    params: Notifier.CountUnreadNotificationsRequest
  ): Promise<Result<Notifier.CountUnreadNotificationsResponse, ErrorV3>> {
    return await this.call<Notifier.CountUnreadNotificationsResponse, ErrorV3>((c) =>
      c.post('/notifier/v1/CountUnreadNotifications', params)
    )
  }

  async setNotificationViewed(
    params: Notifier.MarkNotificationViewedRequest
  ): Promise<Result<Notifier.MarkNotificationViewedResponse, ErrorV3>> {
    return await this.call<Notifier.MarkNotificationViewedResponse, ErrorV3>((c) =>
      c.post('/notifier/v1/MarkNotificationViewed', params)
    )
  }

  async getNotifications(
    params: Notifier.ListNotificationsRequest
  ): Promise<Result<Notifier.ListNotificationsResponse, ErrorV3>> {
    return await this.call<Notifier.ListNotificationsResponse, ErrorV3>((c) =>
      c.post('/notifier/v1/ListNotifications', params)
    )
  }
}
