import { Loans } from '@miimosa/protocol'

import client from './client'

export const simulateInterests = async ({ amount, project_slug }: Loans.SimulateInterestsRequest) => {
  return await client.post<Loans.SimulateInterestsResponse>('/twirp/miimosa.loans.v1.LoansService/SimulateInterests', {
    amount,
    project_slug,
  })
}

export const getFpfStats = async ({}: Loans.FPFStatsRequest) => {
  return await client.post<Loans.FPFStatsResponse>('/twirp/miimosa.loans.v1.LoansService/FPFStats', {})
}
