import { Flex, Heading, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import NextLink from 'next/link'
import { RefObject } from 'react'

import type { FullArticleType, articleType } from '@miimosa/common/types'
import { Icon } from '@miimosa/design-system'

import { Article } from '@components'

const Articles = ({
  slug,
  articles,
  article,
  containerTopRef,
}: {
  slug: string
  articles?: articleType[]
  article?: FullArticleType
  containerTopRef: RefObject<HTMLDivElement>
}) => {
  const { t } = useTranslation('project', { keyPrefix: 'articles' })

  const Up = () => {
    const elementPosition = containerTopRef.current?.offsetTop
    if (elementPosition) {
      const offset = elementPosition - 82 // navbar height

      window.scrollTo({
        top: offset,
        behavior: 'smooth',
      })
    }
  }

  return (
    <Flex
      direction="column"
      w="full"
      rowGap={8}
      minHeight="300px"
      bg="white"
      p={8}
      border="solid 1px"
      borderColor="light_gray"
    >
      {article ? (
        <Article
          title={article.title}
          content={article.content}
          comments={article.comments}
          slug={slug}
          containerTopRef={containerTopRef}
        />
      ) : (
        articles &&
        articles.length > 0 && (
          <Flex direction="column" w="full" rowGap={8} bg="white" p={8}>
            <Heading size="lg">Les actualitées du projet</Heading>
            {articles.map(({ url, title }, i) => (
              <Flex direction={{ base: 'column', md: 'row' }} key={`title_${i}`} columnGap={6} rowGap={6}>
                <Flex direction="column" rowGap={6}>
                  <Flex direction="column">
                    <NextLink href={`/projects/${slug}/articles/${url}`} scroll={false}>
                      <Heading size="md" color="dark_green" onClick={() => Up()}>
                        {title}
                      </Heading>
                    </NextLink>
                    <NextLink href={`/projects/${slug}/articles/${url}`} scroll={false}>
                      <Text
                        size="lg"
                        textDecor="underline"
                        _hover={{ textDecor: 'none', fontWeight: 700 }}
                        color={'dark_green'}
                      >
                        Voir plus
                      </Text>
                    </NextLink>
                  </Flex>
                </Flex>
              </Flex>
            ))}
          </Flex>
        )
      )}
      {!article && (!articles || articles?.length == 0) && (
        <Flex direction="row" alignItems="center" columnGap={3} bg="white" minHeight="300px" w="full" p={8}>
          <Icon name="AverageAmount" color="blue" fill="blue" size="sm" />
          <Heading>{t('none')}</Heading>
        </Flex>
      )}
    </Flex>
  )
}

export default Articles
