import { captureException } from '@sentry/nextjs'

import { FunnelAPI } from '@miimosa/api'
import { Leads } from '@miimosa/protocol'

export const storageKey = 'lead_funnel'

export type inputDescriptorsType = {
  name: Leads.InputName
  options: string[]
  validation: {
    required: boolean
  }
}

export interface FunnelStateType {
  lead_id: string
  funnel_type: Leads.FunnelType
  step?: Leads.Step
  salesforce_ref?: string
  progress: { current_step?: number; step_count?: number; steps?: Leads.StepName[] }
}

const formatData = (data: Record<string, string>) => {
  return Object.keys(data).map((inputValue) => ({
    name: inputValue,
    [typeof data[inputValue] === 'string' ? 'str' : 'number']: data[inputValue],
  })) as Leads.InputValue[]
}

export const fetchLead = async (leadId: string) => {
  const funnelApi = new FunnelAPI()
  try {
    const response = await funnelApi.fetchLead({ lead_id: leadId, funnel_type: 'FUNNEL_TYPE_UNSPECIFIED' })
    if (response.kind == 'success') {
      return response.data
    } else {
      return undefined
    }
  } catch (err) {
    captureException(err)
  }
}

export const goToPreviousStep = async (leadId: string, funnelType: Leads.FunnelType) => {
  const funnelApi = new FunnelAPI()
  try {
    const response = await funnelApi.previousFunnel({ lead_id: leadId, funnel_type: funnelType })
    if (response.kind == 'success') {
      return response.data
    } else {
      return undefined
    }
  } catch (err) {
    captureException(err)
  }
}

export const createLead = async (funnelType: Leads.FunnelType) => {
  const funnelApi = new FunnelAPI()
  try {
    const response = await funnelApi.createFunnel({ funnelType: funnelType })
    if (response.kind == 'success') {
      return response.data
    } else {
      return undefined
    }
  } catch (err) {
    captureException(err)
  }
}

export const updateLead = async (leadId: string, funnelType: Leads.FunnelType, stepData: Record<string, string>) => {
  const formatedData = formatData(stepData)
  const funnelApi = new FunnelAPI()
  try {
    const response = await funnelApi.updateFunnel({ lead_id: leadId, funnel_type: funnelType, answers: formatedData })
    return response
  } catch (err) {
    captureException(err)
  }
}

export const convertLead = async (leadId: string, funnelType: Leads.FunnelType, oldFunnelType: Leads.FunnelType) => {
  const funnelApi = new FunnelAPI()
  try {
    const response = await funnelApi.updateFunnel({
      lead_id: leadId,
      funnel_type: oldFunnelType,
      answers: [
        {
          name: 'INPUT_NAME_FUNNEL_TYPE',
          str: funnelType,
        },
      ],
    })
    if (response.kind == 'success') {
      return response.data
    } else {
      return undefined
    }
  } catch (err) {
    captureException(err)
  }
}
